<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Suporte sinistro essor</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>
    <div class="container mt-5 pb-3">
      <div>
        <div class="row mb-4">
          <div class="col-md-4 mb-2">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Digite o número da apólice"
                v-model="idApolice"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="buscarApolice()"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 order-lg-2">
        <div v-if="!loading">
          <table class="table text-center" v-if="seguro != undefined">
            <thead>
              <tr>
                <th>Nome</th>
                <th>CPF</th>
                <th>Email</th>
                <th>Valor</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Link da cobrança</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ seguro.nome }}
                </td>
                <td>
                  {{ seguro.cpf }}
                </td>
                <td>
                  {{ seguro.email }}
                </td>
                <td>{{ formatacaoMoeda(seguro.valor) }}</td>
                <td>
                  {{ seguro.marcaBike }}
                </td>
                <td>
                  {{ seguro.modeloBike }}
                </td>
                <td>
                  <a :href="seguro.linkCobranca" target="_blank">
                    {{ seguro.linkCobranca }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="seguro != undefined">
            <div
              class="text-center"
              v-if="config.SITUACAO_APROVADA == seguro.situacaoSeguro"
            >
              <b-button
                class="btn btn-danger "
                @click.prevent="exibirModalCancelarSeguro(seguro)"
              >
                Cancelar seguro
              </b-button>
            </div>
            <div class="text-center" v-else>
              <b-badge variant="warning">Seguro já cancelado</b-badge>
            </div>
          </div>
        </div>

        <div v-else class="text-center">
          <lottie-player
            src="/anim/mountain_bike.json"
            background="transparent"
            speed="1.7"
            style="width: 72px; height: 72px; margin:0 auto;"
            autoplay
            loop
            class="mt-3"
          ></lottie-player>
          <div>Carregando, por favor aguarde...</div>
        </div>
      </div>
    </div>
    <ModalCancelarSeguro
      :idUsuarioAdm="idUsuarioAdm"
      :idSeguro="idSeguro"
      :showModalCancelarSeguro="showModalCancelarSeguro"
      @hide="hideModalCancelarSeguro"
    />
  </div>
</template>

<script>
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import SideNav from "../../components/Admin/SideNav";

export default {
  components: {
    SideNav,
    ModalCancelarSeguro: () =>
      import("../../components/Usuario/ModalCancelarSeguro"),
  },
  data() {
    return {
      idApolice: undefined,
      config,
      idSeguro: undefined,
      idUsuarioAdm: undefined,
      loading: false,
      seguro: undefined,
      showModalCancelarSeguro: false,
      formatacaoMoeda,
    };
  },
  beforeRouteEnter(to, from, next) {
    var userInfo = auth.getUserInfo();
    if (userInfo.id) {
      next();
    } else {
      next("/login");
    }
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo.id) {
      this.idUsuarioAdm = userInfo.id;
    }
  },
  methods: {
    exibirModalCancelarSeguro(seguro) {
      this.idSeguro = seguro.idSeguro;
      this.showModalCancelarSeguro = true;
    },
    hideModalCancelarSeguro(status) {
      this.showModalCancelarSeguro = false;
      if (status.modificou) {
        this.seguro = undefined;
        this.idApolice = undefined;
      }
    },
    async buscarApolice() {
      if (!this.idApolice) return;

      this.loading = true;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/seguro/buscarApolice?idApolice=${this.idApolice}`
        );

        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            html: `Não foi encontrada nenhuma apolice para essa apolice`,
          });
          return;
        }

        this.seguro = response.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar essa apolice. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
